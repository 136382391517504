function tabs(tabsParentSelector, tabsSelector, tabsContentSelector, activeClass) {
    const tabsParent = document.querySelectorAll(tabsParentSelector);

    tabsParent.forEach(item => {
        let tabs = item.querySelectorAll(tabsSelector),
            tabsContent = item.querySelectorAll(tabsContentSelector);

        function hideTabContent() {
            
            tabsContent.forEach(item => {
                item.classList.add('hide');
            });

            tabs.forEach(item => {
                item.classList.remove(activeClass);
            });
        }

        function showTabContent(i = 0) {
            tabs[i].classList.add(activeClass);
            tabsContent[i].classList.remove('hide');
        }

        hideTabContent();
        showTabContent();


        tabs.forEach((item, i) => {
            item.addEventListener('click', ()=> {
                hideTabContent();
                showTabContent(i);
            })  
        });
    })

}

export default tabs;