
import 'slick-carousel';
import $ from "jquery";

function fullSlider({slide, slider, prevArrow, nextArrow, mainContainerSelector, sliderBoxSelector, arrowsPar, dotsPar, speedPar, firstSlidePar, variableWidthPar,centerModePar, slidesToShowPar, slidesToScrollPar, appendDotsSelector}) {  
    const slides = document.querySelectorAll(slide),
          mainContainer = document.querySelectorAll(mainContainerSelector),
          sliderBox = document.querySelector(sliderBoxSelector),
          sliderContainer = $(slider),
          prev = $(prevArrow),
          next = $(nextArrow);
    
    let slideIndex = 1,
        // slederboxWidth,
        countBox;
    
        
    //   slederboxWidth = window.getComputedStyle(sliderBox).width;


    // slides.forEach(slide => {
    //     slide.style.width = slederboxWidth;
    // });

    mainContainer.forEach( item => {
        countBox = item.querySelector(".counter_container");
        return countBox;
    })

    sliderContainer.slick({
        arrows: arrowsPar,
        dots: dotsPar,
        customPaging : function(slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return `<a class="dot">${(i+1)}</a>`;
        },
        speed: speedPar,
        initialSlide: firstSlidePar,
        variableWidth: variableWidthPar,
        centerMode: centerModePar,
        slidesToShow: slidesToShowPar,
        slidesToScroll: slidesToScrollPar,
        appendDots: $(appendDotsSelector),
        infinite: false,
        responsive: [
            {
              breakpoint: 400,
              settings: {
                infinite: true
              }
            }
        ]
      });


    function undesablaedPrev() {
        if(slideIndex == 1) {
            prev.disabled = true;
        } else {
            prev.disabled = false;
        }
    }

    function slideIndexUp() {
        if(slideIndex == slides.length) {
            slideIndex = 1;
        } else {
            slideIndex++;
        }
    }

    function slideIndexdown() {
        if(slideIndex == 1) {
            slideIndex = slides.length;
        } else {
            slideIndex--;
        }
    }

    function goToStart() {
        mainContainer.forEach(box => {
            box.scrollIntoView({block:"start", behavior: "smooth"});
        })
    }

    prev.on("click", function(event) {
        event.preventDefault();
        sliderContainer.slick('slickPrev');
        goToStart();
        slideIndexdown();
        undesablaedPrev();
    });
    
    next.on("click", function(event) {
        event.preventDefault();
        
        sliderContainer.slick('slickNext');
        goToStart();
        slideIndexUp();
        undesablaedPrev();
    });

    prev.on("swipe", function(event) {
        event.preventDefault();

        sliderContainer.slick('slickNext');
        slideIndexUp();
        undesablaedPrev();
        goToStart();
    });

    const slickDots = document.querySelectorAll(".dot");

    slickDots.forEach(dot => {
        dot.addEventListener('click', () => {
            goToStart();
        })
     })
}

export default fullSlider;