// 'use strict';
import 'slick-carousel';
import $ from "jquery";
import Inputmask from "inputmask";
import openSubmenu from './modules/openSubmenu';
import headerMenuMobile from './modules/headerMenuMobile';
import modal from './modules/modal';
import openThanksModal from './modules/openThanksModal';
import sliderWithoutnumbers from './modules/sliderWithoutnumbers';
import sliderWithSetings from './modules/sliderWithSetings';
import sliderWithDots from './modules/sliderWithDots';
import swipeToRight from './modules/swipeToRight';
import swipeWithDots from './modules/swipeWithDots';
import openCommentsMobile from './modules/openCommentsMobile';
import readMoreComments from './modules/readMoreComments'; 
import checkRating from './modules/checkRating';
import checkCheckbox from './modules/checkCheckbox';
import calc from './modules/calc';
import openMoreWorks from './modules/openMoreWorks';
import ourTeamTabs from './modules/ourTeamTabs';
import fullSlider from './modules/fullSlider';
import tabs from './modules/tabs';
import tabsOneBtnReview from './modules/tabsOneBtnReview';
import tabsOneBtnPortfolio from './modules/tabsOneBtnPortfolio';

window.addEventListener('DOMContentLoaded', () => {

  try {
    openThanksModal('[data-thanks_modal]');
  } catch(e) {
  } 
  

  //open heder submenu
  try {
    openSubmenu('[data-sub_parent]', '[data-nav_with_sub]', '[data-nav_sub]');
  } catch(e) {
    
  } 

  // Mobile menu
    headerMenuMobile("[data-menuMobile]", "[data-mobileOpen]", "[data-mobileClose]", ".blur_background");

  // modals
    try {
      function changeValue() {
        const submitBtns = document.querySelectorAll('.wpcf7-submit');

        submitBtns.forEach(btn => {
          btn.getAttribute('value');

          btn.setAttribute('value', "Отправить заявку");
        })
      }
      
      changeValue();

      modal(".order_call", '[data-back_call_modal]', '[data-modalClose]');
        
    } catch(e) {
      
    } 
     
    try {
      modal("[data-submit]", '[data-thanks_modal]', '[data-modalClose]');
    } catch(e) {
      
    } 

    try {
      modal("[data-free_consultation_btn]", '[data-back_call_modal]', '[data-modalClose]');
    } catch(e) {
     
    } 
     
  // home first slider
    try {
      if(window.innerWidth < 1600) {
        sliderWithSetings({
          slide: '.offer__slide', 
          slider: "[data-slider]",
          prevArrow: ".offer__slider-prev",
          nextArrow: ".offer__slider-next",
          totalCounter: '#total', 
          currentCounter: '#current',
          arrowsPar: true, 
          dotsPar: false, 
          speedPar: 700, 
          firstSlidePar: 0, 
          variableWidthPar: false,
          centerModePar: false, 
          slidesToShowPar: 1, 
          slidesToScrollPar: 1
        });
      } else {
        sliderWithSetings({
          slide: '.offer__slide', 
          slider: "[data-slider]",
          prevArrow: ".offer__slider-prev",
          nextArrow: ".offer__slider-next",
          totalCounter: '#total', 
          currentCounter: '#current',
          arrowsPar: true, 
          dotsPar: false, 
          speedPar: 1500, 
          firstSlidePar: 0, 
          variableWidthPar: true,
          centerModePar: false, 
          slidesToShowPar: 1, 
          slidesToScrollPar: 1
        });
      }
      
      } catch(e) {
        
      } 

    // swipe to right
      try {
        if(window.innerWidth < 760) {
          swipeToRight({ 
            slider: "[data-services]",
            arrowsPar: false, 
            dotsPar: false, 
            speedPar: 500, 
            firstSlidePar: 0, 
            variableWidthPar: true,
            centerModePar: false, 
            slidesToShowPar: 1, 
            slidesToScrollPar: 1
          });
        }
      } catch(e) {
        
      } 
    
    // portfolio preview slider
      try {
        if(window.innerWidth > 1420) {
          sliderWithoutnumbers({
            slider: "[data-portfolio_preview]",
            prevArrow: "[data-portfolioPrewievPrev]",
            nextArrow: "[data-portfolioPrewievNext]",
            arrowsPar: true, 
            dotsPar: false, 
            speedPar: 700, 
            firstSlidePar: 0, 
            variableWidthPar: false,
            centerModePar: false, 
            slidesToShowPar: 1, 
            slidesToScrollPar: 1
          });
        } else {
          document.querySelector('.portfolio_preview__slider').style.width = '100%';
        }
      } catch(e) {
        
      } 

    // open comments
      try {
        if(document.querySelector('.comments')){
          readMoreComments({
            commentSelector: '[data-comments]',
            textBox: '.comments__list__item', 
            textSelector: '[data-comments-text]',
            openBtn: '[data-comment_open]',
            closingBtn: '[data-comment_close]',
            openClass: 'comments__item__text_container--opened',
            cutLenghtDesc: 625,
            cutLenghtMobile: 300});
          openCommentsMobile();
        } else {
          readMoreComments({
            commentSelector: '[data-comments]',
            textBox: '.comments__list__item', 
            textSelector: '[data-comments-text]',
            openBtn: '[data-comment_open]',
            closingBtn: '[data-comment_close]',
            openClass: 'comments__item__text_container--opened',
            cutLenghtDesc: 800,
            cutLenghtMobile: 500});
        }
        
        checkRating('[data-aboutCommentBox]', '.star_icon', '.rating_current', 'star_icon--checked');
      } catch(e) {
        
      } 
    
    // comments slider
      try {
        if(window.innerWidth > 1200) {
          sliderWithDots({
            slide: '[data-comments]', 
            slider: ".comments__list",
            prevArrow: ".comments__arrow--prev",
            nextArrow: ".comments__arrow--next",
            currentCounter: '.current_number',
            indicatorTotalSelector: '[data-totalDots]',
            indicatorCurrentSelector: '[data-currentDot]',
            arrowsPar: true, 
            dotsPar: false, 
            speedPar: 700, 
            firstSlidePar: 0, 
            variableWidthPar: false,
            centerModePar: false, 
            slidesToShowPar: 2, 
            slidesToScrollPar: 1
          });
        } else {
          document.querySelector('.comments__list').style.width = '100%';
        }       
      } catch(e) {
        
      } 


      // check checkbox
      try {
        checkCheckbox('[data-checkbox_label]', '[data-checkbox]');
      } catch(e) {
        
      }

      //calc
      try {
        calc();
      } catch(e) {
        
      } 

      //open more stages_of_works
      try {
        openMoreWorks();
      } catch(e) {
       
      } 

      //our team tabs
      try {
        ourTeamTabs('.our_team__item', '.designer_card', 'our_team__item--current', '[data-designersNextArrow]', '[data-designersPrevArrow]', '.team_list__box', '.team_list__box');

        if(window.innerWidth < 1400) {
          swipeWithDots({ 
            slide: ".designer_card",
            slider: ".designer_cards__list",
            indicatorTotalSelector: '[data-teamTotalDots]', 
            indicatorCurrentSelector: '[data-teamCurrentDot]',
            arrowsPar: false, 
            dotsPar: false, 
            speedPar: 500, 
            firstSlidePar: 0, 
            variableWidthPar: true,
            centerModePar: false, 
            slidesToShowPar: 2, 
            slidesToScrollPar: 1
          });
        }
      } catch(e) {
      
      } 

      try {
        if(window.innerWidth > 1200) {
          openSubmenu('.designer_card', '[data-with_sub]', '[data-sub]');
        }
      } catch(e) {
        
      } 
    
       
      //packages of services
      try {
        function showBtnOnPackage(packageElement, btn) {
          const packageBox = document.querySelectorAll(packageElement);

          packageBox.forEach(packageItem => {
            const showedBtn = packageItem.querySelector(btn);
            
            packageItem.addEventListener('mouseover', ()=> {
              showedBtn.classList.add('show');
            });

            packageItem.addEventListener('mouseout', ()=> {
              showedBtn.classList.remove('show');
            });

          })
        };

        showBtnOnPackage('[data-package]', '[data-packageBtn]');
      } catch(e) {
        
      } 

      // portfolio slider

      try {
        tabs('.portfolio_main','[data-tabs]','[data-tabs_content]', 'portfolio__tab_btn--active');
      } catch(e) {
        
      } 

      try {
        
        if(window.innerWidth > 500) {
          fullSlider({ 
            slide: "[design_project_tab_slide]",
            slider: "[data-design_project_tab_Field]",
            prevArrow: "[data-design_project_tab_prev]", 
            nextArrow: "[data-design_project_tab_next]", 
            mainContainerSelector: "[data-design_project_tab]",
            sliderBoxSelector: ".design_project__tab",
            arrowsPar: true, 
            dotsPar: true, 
            speedPar: 500, 
            firstSlidePar: 0, 
            variableWidthPar: true,
            centerModePar: false, 
            slidesToShowPar: 1, 
            slidesToScrollPar: 1,
            appendDotsSelector: ".counter_container",
          });
        } else {
          tabsOneBtnPortfolio({
              tabsParentSelector: '.design_project__tab', 
              articleCards: '[data-portfolioSlide]', 
              btnSelector: '.portfolio__see_mor_btn'
            });
          }
      } catch(e) {
       
      } 

      try {
        if(window.innerWidth > 500) {
          fullSlider({ 
            slide: "[data_maked_repair_slide]",
            slider: "[data-maked_repair_tab_Field]",
            prevArrow: "[data-design_maked_repair_prev]", 
            nextArrow: "[data-design_maked_repair_next]", 
            mainContainerSelector: "[data-maked_repair_tab]",
            sliderBoxSelector: ".maked_repair__tab",
            arrowsPar: true, 
            dotsPar: true, 
            speedPar: 500, 
            firstSlidePar: 0, 
            variableWidthPar: true,
            centerModePar: false, 
            slidesToShowPar: 1, 
            slidesToScrollPar: 1,
            appendDotsSelector: ".counter_container--repair"
          });
        } else {
          tabsOneBtnPortfolio({
            tabsParentSelector: '.maked_repair__tab', 
            articleCards: '[data-portfolioSlide]', 
            btnSelector: '.portfolio__see_mor_btn',
          });
        }
      } catch(e) {
        
      }      

      // portfolio ptoject template
      
      try {
        readMoreComments({
          commentSelector: '.portfolio_project',
          textBox: '.portfolio_project__text', 
          textSelector: '[data-cut_portf_text]',
          openBtn: '[data-comment_open]',
          closingBtn: '[data-comment_close]',
          openClass: 'show',
          cutLenghtDesc: 2000,
          cutLenghtMobile: 310});
      } catch(e) {
        
      } 

      try {
        if(window.innerWidth > 1200) {
          sliderWithoutnumbers({
            slider: ".similar_projects__slider",
            prevArrow: "[data-similarPrevArrow]",
            nextArrow: "[data-similarNextArrow]",
            arrowsPar: true, 
            dotsPar: false, 
            speedPar: 700, 
            firstSlidePar: 0, 
            variableWidthPar: false,
            centerModePar: false, 
            slidesToShowPar: 4, 
            slidesToScrollPar: 4
          });
        } else {
          swipeToRight({ 
            slider: ".similar_projects__slider",
            arrowsPar: false, 
            dotsPar: false, 
            speedPar: 500, 
            firstSlidePar: 0, 
            variableWidthPar: true,
            centerModePar: false, 
            slidesToShowPar: 1, 
            slidesToScrollPar: 1
          });
        }
      } catch(e) {
       
      } 
 

      // review 
      try {
        if(window.innerWidth > 400) {
          fullSlider({ 
            slide: "[data-reviewSlide]",
            slider: "[data-reviewField]",
            prevArrow: "[data-reviewPrev]", 
            nextArrow: "[data-reviewNext]", 
            mainContainerSelector: ".review_main",
            sliderBoxSelector: ".wrapper_full__container",
            arrowsPar: true, 
            dotsPar: true, 
            speedPar: 500, 
            firstSlidePar: 0, 
            variableWidthPar: true,
            centerModePar: false, 
            slidesToShowPar: 1, 
            slidesToScrollPar: 1,
            appendDotsSelector: ".counter_container"
          });
        } else {
          tabsOneBtnReview({
            tabsParentSelector: '.reviews__main_container', 
            articleCards: '[data-comments]', 
            btnSelector: '.portfolio__see_mor_btn',
          });
        }
      } catch(e) {
      
      }

      try {
        const telInput = document.querySelector(".calc-tel input");
        const yourTelInput = document.querySelector(".your-tel input");
        var im = new Inputmask("+7 (999) 999-9999");
        im.mask(telInput);
        im.mask(yourTelInput);
      } catch(e) {
        
      } 

     
});



