function closeModal(modalSelector) {
    const modal = document.querySelector(modalSelector);
    modal.classList.add('hide');
    modal.classList.remove('show');
    document.body.style.overflow = '';
}

function openModal(modalSelector) {
    const modal = document.querySelector(modalSelector);
    modal.classList.add('show');
    modal.classList.remove('hide');
    document.body.style.overflow = 'hidden';  
}

function openThanksModal(modalSelector) {

    const formSent = document.querySelectorAll('form');

    formSent.forEach(item => {
      item.addEventListener('wpcf7mailsent', (e) => {
        e.preventDefault();
            openModal(modalSelector);
            setTimeout(() => closeModal(modalSelector), 3000);         
      }) 
    });
    
    document.addEventListener('keydown', (e) => {
        if (e.code === "Escape" && modal.classList.contains('show')) { 
            closeModal(modalSelector);
        }
    });
    
}

export default openThanksModal;
export {closeModal, openModal};